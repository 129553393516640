<template>
  <div id="riskReport">
    <el-dialog
      :title="riskReportFormTitle"
      width="680px"
      :visible.sync="riskReportDialogVisible"
      :close-on-click-modal="false"
      @close="riskReportDialogClose"
    >
      <el-form
        ref="riskReportFormRef"
        :model="riskReportForm"
        :rules="riskReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="文件编号" prop="fileNo">
              <el-input v-model="riskReportForm.fileNo" placeholder="请输入文件编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告名称" prop="reportName">
              <el-input v-model="riskReportForm.reportName" placeholder="请输入报告名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="riskReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品型号" prop="spec">
              <el-input v-model="riskReportForm.spec" placeholder="请输入产品型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="riskReportForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editDate">
              <el-date-picker v-model="riskReportForm.editDate" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="riskReportForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="reviewDate">
              <el-date-picker v-model="riskReportForm.reviewDate" placeholder="请选择审核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="riskReportForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approvedDate">
              <el-date-picker v-model="riskReportForm.approvedDate" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文件上传">
              <el-scrollbar style="height: 100px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="riskReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="riskReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="报告名称">
        <el-input v-model="searchForm.reportName" placeholder="请输入报告名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="riskReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="fileNo" label="文件编号" />
      <el-table-column prop="reportName" label="报告名称" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="产品型号" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{ scope.row.editDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvedDate">{{ scope.row.approvedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="riskReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addRiskReport, deleteRiskReport, updateRiskReport, selectRiskReportInfo, selectRiskReportList } from '@/api/technology/riskReport'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      riskReportDialogVisible: false,
      riskReportFormTitle: '',
      riskReportForm: {
        id: '',
        fileNo: '',
        reportName: '',
        productName: '',
        spec: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approvedDate: '',
        reportUrl: '',
        reportUrlName: ''
      },
      riskReportFormRules: {
        fileNo: [{ required: true, message: '文件编号不能为空', trigger: ['blur', 'change']}]
      },
      riskReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        reportName: ''
      },
      fileList: []
    }
  },
  created () {
    selectRiskReportList(this.searchForm).then(res => {
      this.riskReportPage = res
    })
  },
  methods: {
    riskReportDialogClose () {
      this.$refs.riskReportFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    riskReportFormSubmit () {
      if (this.riskReportFormTitle === '风险管理报告详情') {
        this.riskReportDialogVisible = false
        return
      }
      this.$refs.riskReportFormRef.validate(async valid => {
        if (valid) {
          this.riskReportForm.reportUrl = this.fileList.map(file => file.path).join(',')
          this.riskReportForm.reportUrlName = this.fileList.map(file => file.name).join(',')
          if (this.riskReportFormTitle === '新增风险管理报告') {
            await addRiskReport(this.riskReportForm)
          } else if (this.riskReportFormTitle === '修改风险管理报告') {
            await updateRiskReport(this.riskReportForm)
          }
          this.riskReportPage = await selectRiskReportList(this.searchForm)
          this.riskReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.riskReportFormTitle = '新增风险管理报告'
      this.riskReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRiskReport(row.id)
        if (this.riskReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.riskReportPage = await selectRiskReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.riskReportFormTitle = '修改风险管理报告'
      this.riskReportDialogVisible = true
      this.selectRiskReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.riskReportFormTitle = '风险管理报告详情'
      this.riskReportDialogVisible = true
      this.selectRiskReportInfoById(row.id)
    },
    selectRiskReportInfoById (id) {
      selectRiskReportInfo(id).then(res => {
        this.riskReportForm.id = res.id
        this.riskReportForm.fileNo = res.fileNo
        this.riskReportForm.reportName = res.reportName
        this.riskReportForm.productName = res.productName
        this.riskReportForm.spec = res.spec
        this.riskReportForm.editor = res.editor
        this.riskReportForm.editDate = res.editDate
        this.riskReportForm.reviewer = res.reviewer
        this.riskReportForm.reviewDate = res.reviewDate
        this.riskReportForm.approver = res.approver
        this.riskReportForm.approvedDate = res.approvedDate
        if (res.reportUrl) {
          const reportUrl = res.reportUrl.split(',')
          const reportUrlName = res.reportUrlName.split(',')
          this.fileList = []
          for (let i = 0; i < reportUrl.length; i++) {
            this.fileList.push({
              name: reportUrlName[i],
              path: reportUrl[i]
            })
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRiskReportList(this.searchForm).then(res => {
        this.riskReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRiskReportList(this.searchForm).then(res => {
        this.riskReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRiskReportList(this.searchForm).then(res => {
        this.riskReportPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#riskReport .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
